html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: black;
}

.header {
    background-color: #f7faff;
    height: 120px;
    display: grid;
    align-items: center;
}

.wraper-header {
    margin: auto;
    width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo img {
    width: 200px;
}

.navbar-na {
    list-style: none;
    display: flex;
    align-items: center;
}

.menu ul {
    margin: 0px;
}

.nav-links {
    display: block;
    padding: 0.5rem 1rem;
}
.nav-item a{
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    color: #000;
}
.nav-item a:hover{
    color: #D90000;
    text-decoration: none;
}
#active{
    color: #d90000;
}

#callnow{
    padding: 12px 22px;
    background-color: #d90000;
    margin-left: 20px;
    color: #fff;
    border-radius: 4px;
}

.heros {
    background-image: url(../Images/bg.jpg);
    background-position: center;
    background-size: cover;
}

.hero {
    background-color: #1a1a1ac7;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

}

.wraper-hero {
    margin: auto;
    max-width: 1100px;
    display: grid;
    grid-template-columns: 50% 50%;
    height: 600px;
    align-items: center;
}

.w-wrap {
    height: 360px;
    justify-content: space-around;
    background-color: #fff;
    padding: 50px;
    display: flex;
    flex-direction: column;
}

.w-wrap h1 {
    font-size: 40px;
    font-weight: 700;
}

.w-wrap h5 {
    font-size: 22px;
    font-weight: 700;
}

.w-wrap p {
    padding: 0;
    margin: 0;
    font-size: 20px;
}

.sec-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.sec-2 a:hover{
    text-decoration: none;
}

.sec-2 button {
    border: 0px;
    width: 240px;
    height: 70px;
    color: #fff;
    background-color: #d90000;
    border-radius: 4px;
    font-size: 15px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.button a{
    color: #f7faff;
    text-decoration: none;
}
.button a:hover{
    color: #f7faff;
    text-decoration: none;
}
.sec-2 button span svg {
    font-size: 19px;
}

.wrap-about {
    display: grid;
    grid-template-columns: 45% 50%;
    padding: 140px 0;
    max-width: 1100px;
    margin: auto;
    overflow: hidden;
    grid-gap: 60px;
    align-items: center;
}

.wrap-about img {
    width: 100%;
}

.imgzz {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}

.img3 {
    margin-top: -156px;
}

.img3 img {
    width: 98%;
}

.sect-1 h4 {
    font-size: 24px;
    font-weight: 700;

}

.sect-1 p {
    font-size: 15px;
    color: #000000;
    padding-top: 15px;
}

.sect-1 ul li {
    font-size: 15px;
    color: #000000;
    padding-bottom: 5px;
}

.services {
    background-color: #f7faff;
    padding: 90px 0;
}

.sevice-wraper {
    margin: auto;
    max-width: 1100px;
    text-align: center;
}

.sevice-wraper h1 {
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 45px;
}

.icons {
    display: grid;
    grid-template-columns: 22.5% 22.5% 22.5% 22.5%;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
}

.card-icon {
    background-color: #D90000;
    border-radius: 4px;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 30px 40px;
}

.card-icon svg {
    font-size: 70px;
    background-color: #fff;
    padding: 10px;
    border-radius: 50px;
    color: #D90000;
}

.card-icon h4 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    color: #fff;
}

.Faqs-wrap {
    padding: 20px 0 80px 0;
    background-color: #f7faff;
}

.Faqs-wrap h1 {
    font-size: 35px;
    text-align: center;
    font-weight: 700;
}

.Faqs-wrap h5 {
    text-align: center;
    font-size: 17px;
    padding-bottom: 40px;
}

.main-faq {
    display: flex;
    justify-content: center;
}

.appoinment {
    background-color: #D90000;
    padding: 70px 0;
}

.wraperz {
    margin: auto;
    max-width: 1100px;
    display: flex;
    gap: 190px;
    align-items: center;
}

.sectt-1 h4 {
    font-size: 17px;
    color: #f7faff;
    font-weight: 700;
    padding-bottom: 10px;
}

.sectt-1 h1 {
    font-size: 38px;
    color: #f7faff;
    font-weight: 700;
    margin: 0;
}

.sectt-2 button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 16px 24px;
    color: #f7faff;
    background-color: rgba(247, 250, 255, 0);
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #f7faff;
    box-shadow: 0px 8px 16px -8px rgb(56 125 255 / 50%);
    border-radius: 6px;
}
.sectt-2 button svg{
    margin-top: -4px;
}

.swiper {
    height: 350px;
  }
  
  .swiper-slide {
    padding: 0px 30px;
    text-align: center;
    font-size: 18px;
    background: #ffffff;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-prev, .swiper-rtl .swiper-button-next{
    font-size: 10px !important;
    left: 0px !important;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    font-size: 10px !important;
    left: auto !important;
    right: 0px !important;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 18px !important;
    color: #000 !important;
  }
  .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    font-size: 18px !important;
    color: #000 !important;
}
.swiper-pagination-bullet-active{
    background: #000 !important;
}
.reviews{
    background-color: #f7faff;
}
.aa-reviews{
    padding: 110px 0;
    background-color: #fff;
    border-radius: 0 0 9% 9%;
}
.reviews h1{
    font-size: 35px;
    text-align: center;
    font-weight: 700;
}
.swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,5px)) !important;
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,5px)) !important;
}
.testtt p{
    font-size: 15px;
    color: #535961;
}
.testtt h4{
    font-size: 19px;
    font-weight: 600;
}
.testtt h6{
    font-size: 15px;
    color: #727982;
    font-weight: 600;
}

.contact{
    background-color: #f7faff;
    padding: 0 0 150px 0;
}
.red-part{
    max-width: 1100px;
    margin: auto;
    background-color: #D90000;
    padding: 50px;
    display: grid;
    grid-template-columns: 30.66% 30.66% 30.66%;
    grid-gap: 2%;
    border-radius: 6px 6px 0 0 ;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #00000012;
    box-shadow: 0px 0px 80px -16px rgb(0 0 0 / 16%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.address{
    display: flex;
    gap: 15px;
}
.address span svg{
    font-size: 60px;
    background-color: rgba(255, 255, 255, 0.16);
    padding: 16px;
    border-radius: 50%;
    color: #fff;
}
.address span h4{
    font-size: 22px;
    font-weight: 600;
    color: #fff;
}
.address span p{
    font-size: 17px;
    color: #fff;
    font-family: 'Lato', sans-serif;
    line-height: 30px;
}
.white-part{
    max-width: 1100px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0px;
    background-color: #fff;
    border-radius: 0px 0px 6px 6px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #00000012;
    box-shadow: 0px 0px 80px -16px rgb(0 0 0 / 16%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.contact-form{
    padding: 40px;
}
.button-part{
    padding: 40px;
}
.inputs{
    width: 100%;
    margin: 10px 0;
    padding: 12.75px;
    color: #666;
    border: 1px solid #dddddd;
    background-color: #FAFAFA;
    border-radius: 2px;
}
.button-part button {
    border: 0px;
    width: 240px;
    height: 70px;
    color: #fff;
    background-color: #d90000;
    border-radius: 4px;
    font-size: 15px;
    margin: 20px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.submit{
    padding: 12px 22px;
    background-color: #d90000;
    color: #fff;
    border-radius: 4px;
    border: 0;
}
.footer{
    background-color: #1a1a1a;
}
.ftrrr{
    max-width: 1100px;
    margin: auto;
}
.clss{
    padding: 60px 0px;
    display: grid;
    grid-template-columns: 50% 50%;
}
.logoz img{
    width: 466px;
}
.iconm{
    display: flex;
    gap: 20px;
    justify-content: end;
}
.iconm svg{
    color: #f7faff;
    font-size: 24px;
}
.coppy hr{
    background-color: #f7faff2f;
    width: 100%;
    margin: 0;
}
.coppy h5{
    text-align: center;
    color: #FAFAFA;
    margin: 0;
    padding: 30px;
    font-size: 17px;
    font-family: 'Lato', sans-serif;
    line-height: 35px;
}
.coppy a{
    color: #fff;
}
.coppy img{
    width: 50px;
    border-radius: 50%;
}


.mobile{
    display: none;
}
.nav {
  width: 100%;
  background-color: #f7faff;
  display: flex;
  align-items: center;
}
.nav > .nav-header {
  flex: 0.95;
  padding-left: 1rem;
}
.nav > .nav-header > .nav-title img{
  width: 180px;
}
.nav > .nav-list {
  display: flex;
  gap: 2rem;
}
.nav > .nav-list > li {
  list-style-type: none;
}
.nav > .nav-list > li a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.nav > #nav-check {
    display: none;
}
@media (max-width: 480px) {
    .header{
        display: none;
    }
    .mobile{
        display: block;
    }
  .nav {
    padding: 25px;
  }
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0.2rem;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding-top: 36.5px;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #d90000;
  }
  .nav > .nav-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background-color: #f7faff;
    height: 0;
    transition: all 0.3s ease-in;
    top: 82px;
    left: 0;
    overflow: hidden;
  }
  .nav > .nav-list > li {
    width: 100%;
    margin-top: 1.5rem;
  }
  .nav > #nav-check:checked ~ .nav-list {
    height: 330px;
  }
}

@media (max-width: 480px) {
    .wraper-hero {
        grid-template-rows: 50% 50%;
        grid-template-columns: 100%;
    }
    .w-wrap {
        margin: 30px;
        height: 300px;
        padding: 40px;
    }
    .w-wrap h1 {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    .w-wrap h5 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    .w-wrap p {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    .wrap-about {
        grid-template-columns: 100%;
        padding: 80px 0;
        margin: 0 30px;
    }
    .img3 img {
        width: 100%;
    }
    .img3 {
        margin-top: -59px;
    }
    .icons {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        margin: 30px;

    }
    .card-icon {
        padding: 50px 40px;
    }
    .sevice-wraper h1 {
        font-size: 23px;
        font-weight: 600;
        padding-bottom: 45px;
        padding: 30px;
    }
    .Faqs-wrap {
        padding: 30px;
    }
    .accordion-button.collapsed {
        font-size: 14px;
        font-weight: 600;
        text-align: start;
    }
    .accordion-button {
        font-size: 14px;
        font-weight: 600;
        text-align: start;
        padding: 20px 50px 20px 17px;
    }
    .wraperz{
        display: block;
        padding: 30px;
        text-align: center;
    }
    .sectt-2{
        display: flex;
        justify-content: center;
        padding: 20px;
    }
    .services {
        background-color: #f7faff;
        padding: 40px 0;
    }
    .test-mob{
        display: block !important;
        max-width: 480px;
        margin: auto;
    }
    .test{
        display: none;
    }
    .reviews{
        padding: 30px;
        background-color: #fff;
    }
    .red-part{
        grid-template-columns: 100%;
    }
    .address{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .white-part {
        grid-template-columns: 100%;
    }
    .contact-form h3{
        text-align: center;
    }
    .contact{
        padding: 0;
    }
    .logoz img {
        width: 100%;
    }
    .button-part{
        display: grid;
        justify-content: center;
    }
    .clss{
       grid-template-columns: 100%;
       padding: 40px;
       grid-gap: 30px;
    }
    .iconm {
        justify-content: center;
    }
}
.test-mob{
    display: none;
}
.test{
    max-width: 1100px;
    margin: auto;
}
.button-part a:hover{
    text-decoration: none;
}
.sectt-2 a:hover{
    text-decoration: none;
}

.grow img{
    width: 50px;
    border-radius: 50%;
}